// .article-wrapper > .article-title + .summary  + .content
// p img h2 + h3

.article-wrapper .article-title {
    font-size: 30px;
    color: #262626;
    letter-spacing: 0;
    text-align: justify;
    line-height: 42px
}

.article-wrapper .summary {
    font-size: 16px;
    color: #787878;
    letter-spacing: 0;
    line-height: 24px
}

.article-wrapper .summary:after {
    display: block;
    width: 58px;
    content: "";
    border: .5px solid #ddd;
    margin: 20px 0
}

.article-wrapper .content {
    font-size: 16px;
    color: #262626;
    letter-spacing: 0;
    text-align: justify;
    line-height: 30px
}

.article-wrapper .content img {
    max-width: 690px;
    display: block;
    border-radius: 5px;
    margin: 30px auto
}


.article-wrapper .content address {
    position: relative;
    font-size: 16px;
    line-height: 30px;
    border: 0;
    font-weight: 400;
    margin-bottom: 26px
}

.article-wrapper .content address p {
    margin-bottom: 0;
    color: #262626;
    font-weight: bolder
}

.article-wrapper .content address strong {
    font-weight: 600
}

.article-wrapper .content h2 {
    font-weight: 600;
    margin: 30px 0 14px;
    font-size: 20px;
    line-height: 28px;
    position: relative;
    color: #4285f4
}

.article-wrapper .content blockquote {
    padding: 6px 0 6px 45px;
    position: relative;
    margin: 30px 0;
    border: 0
}

.article-wrapper .content blockquote:before {
    display: block;
    width: 24px;
    height: 24px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAz9JREFUaAXtWDuIE0EYvs2DBFRSaaFoYuUDwU4sBK2EEwQbLbQQQYjIWdyhkIeRYJ53haAiGBRR1CZae51go4docaCB4wSjiCBiEQRJcnn4DefA756zu7P7d87CMo98/zff9+/szmQmJsxlMmAyYDJgMvA/Z8DiMJ/NZo+Mx+OpSCQyXS6Xl/xy+uEJ+R1MxjUajSjEX0N7cjgcTsp+3dIvT2AD7Xb7AsTuwL2USqVu6QqXeL88gQzgkW9E9q8IEaFQaDqdTq9IQTplEJ5ABiC+DKEJy7KeVavVeR3RFBuEx7eBQqGwF8LPQshKOByeoYJ06kF5fBsYDAbXkbkQTNwM8uUJyuPLQCaTOQ7xByH+eyKRuKqTcYrl4NE2UCwW4xAxJ4TAxGWI6FBRXutcPNoGer3eRYhM4V6Mx+N3vQq247h4tFbifD6/BYuVWGnXYfocqtVqL+zCvLS5eMRYWk9gNBrNIkaIf+pXvBiUi0dweTaArO3HnD8J8d1YLHZJBPu5uHjk2J6mEIRbuVxuAeU+GHiM4I+4t6M9RHsZq/DzSqXyUpKqSi4eyu/JAJb60xj8PsQOUEYoAam/xYJ2HkZek76/qlw8lNTVAD5367vd7lcEbaCB/6oLg7jT2Fbcs//OxWPndX0H8Ll7giBX8YJYPB3cdzDd1myruXjsBhyfALK2Gdn/hCDVtLHzyfZnrBG7EP9LdHDxSHJaOj6Bfr8/BbCueMG/DbEn5EBcPJKPlo4GMB2OUrBOHd/6YxLPxSP5aKk00Gw2wwDupmCdOl7mPQLPxaMaW2mg1WrFkDnl7ypC2Y/YmKhz8Uhee6kU+OcF/GkP0Gj/EFguHtW4SgMiANPgjSrQrR+xCxLDxSP5aOloAMCHFKxZf0DwXDyEcrXqaADHJI8Ae78myr1jHrvVVxLGxSP5aOloQByTRKPRUwjQ+df1BZu7M3QQLh7KKeuOBgSoVCotYpN2GPNYrMiOFzDvgD2AvdA3O5CLx85r2TtU7Xq9nuh0OjP4PJ4DZhPFQfgH3LeTyeQNt8MtLh45vmcDMgAGLJzl7MRxyFbRhwPdZRyriP8HWhcXj9agBmwyYDJgMmAyYDJgMsCcgd/k7L42unVupgAAAABJRU5ErkJggg==) no-repeat 0 0;
    background-size: 100%;
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0
}

.article-wrapper .content blockquote p {
    margin-bottom: 0;
    padding: 6px 0;
    word-wrap: break-word;
    line-height: 28px;
    color: #787878
}

.article-wrapper .content h3 {
    font-size: 18px;
    margin: 40px 0 14px;
    line-height: 23px;
    font-weight: 600;
    position: relative;
    padding-left: 10px
}

.article-wrapper .content h3:before {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    width: 4px;
    background: #4285f4;
    display: block;
    content: ""
}

.article-wrapper .content ul {
    padding: 0 0 0 20px;
    margin-bottom: 22px
}

.article-wrapper .content ul li {
    list-style-type: square
}

.article-wrapper .content p {
    margin-bottom: 26px;
    word-wrap: break-word
}

.article-wrapper .content p a {
    color: #262626;
    border: solid #999;
    border-width: 0 0 1px;
    padding: 0 0 1px;
    word-wrap: break-word
}

.article-wrapper .content p a:hover {
    color: #4d7fd0;
    border-color: #4d7fd0
}

.article-wrapper .content p strong {
    font-weight: 600
}

.article-wrapper .content p img {
    max-width: 690px;
    display: block;
    border-radius: 5px;
    margin: 30px auto
}

.article-wrapper .content {

    position: relative;
    overflow: hidden;
    width: 100%;

    .ql-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    .Ratio-ratio {
        margin-bottom: 26px;
    }
}



@media (max-width: 991.98px) {
    .article-wrapper .content img {
        max-width: 80%;
    }
}