//
// _table.scss
//

.table {
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

@include media-breakpoint-up(md) {
  .table-filter {
    padding-right: 220px;
    position: relative;

    .handle-action {
      position: absolute;
      justify-content: flex-start !important;
      padding: 0 !important;
      bottom: 0;
      right: 0;
    }
  }
}

.table-filter {

  .btn-group .btn-outline-light {

    border-color: $input-border-color;

    &.active {
      color: $input-focus-border-color;
      border-color: $input-focus-border-color;
      background: transparent;
      z-index: 2;
    }

    &:hover {
      color: $input-focus-border-color;
      background: transparent;
    }
  }

}

// react table
.react-bootstrap-table > table {
  border-top: 1px dashed $table-border-color;

  th.sortable {
    cursor: pointer;
  }

  th input{
    display: none;
  }

  tbody tr {
    cursor: default;
  }
}

// report table

.table.table.reports {
  border-spacing: 0;
  border-collapse: separate;
}

.table.reports th:first-child,
.table.reports td:first-child {
  position: sticky;
  left: 0px;
  background-color: #e6e6e6;
  color: #373737;
}

.table.reports th:nth-child(2),
.table.reports td:nth-child(2) {
  position: sticky;
  left: 100px;
  background-color: #e6e6e6;
  color: #373737;
}

.table.reports th:nth-child(3),
.table.reports td:nth-child(3) {
  position: sticky;
  left: 200px;
  background-color: #e6e6e6;
  color: #373737;
}
