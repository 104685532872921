//
//job-list.scss
//

.company-des {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.job-list-menu {
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;

  .nav-item {
      .nav-link {
          padding: 10px 18px;
          color: $dark;
          font-weight: 500;
          transition: all 0.5s ease;
          border-radius: 10px;

          &.active {
              color: $white;
              background-color: $primary;
          }
      }
  }
}

.job-box {
  overflow: hidden;
  transition: all 0.5s ease;
  border-width: 1px;

  .bookmark-label {
      width: 200px;
      position: absolute;
      top: -5px;
      left: -84px;
      transform: rotate(-45deg);
      z-index: 1;
      color: $white;
      background: rgba($primary, 0.15);
      transition: all 0.5s ease;
      padding: 6px;
      font-size: 20px;
      padding-top: 8px;
  }

  &:hover {
      transform: translateY(-8px);
      border-color: $primary;

      .bookmark-label {
          background-color: $primary;
      }
  }
}

//job-list page

.filler-job-form {
  i {
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      left: 20px;
      z-index: 1;
      color: $primary;
      font-size: 20px;
  }
}

.filter-job-input-box,
.filter-job-input-box.form-select,
.filler-job-form .choices__inner {
  box-shadow: none !important;
  font-size: 14px;
  height: 44px;
  padding: 6px 6px 6px 45px;
  border-radius: 6px;

  &:focus {
      border-color: $gray-300;
  }
}


.side-bar {
  .custom-control {
      margin: 12px 22px;
  }

  .form-check-input {
      width: 16px;
      height: 16px;
  }

  .accordion {
      .accordion-item {
          border: none;

          .accordion-button {
              background-color: rgba($primary, 0.15);
              color: $dark;
              box-shadow: none;
              font-size: 15px;

              &::after {
                  content: "\F0140";
                  font-family: 'Material Design Icons';
                  background: none;
              }
          }
      }
  }
}

.tag-cloud {
  color: $text-muted;
  background-color: $light;
  transition: all 0.5s ease;
  &:hover {
      color: $white;
      background-color: $primary;
  }
}

.noUi-horizontal {
  height: 10px;
  background: $white;
  border-color: $gray-400;

  .noUi-handle {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      border-color: $primary;
      background: $primary;
      box-shadow: none;
      &::after,
      &::before {
          content: "";
          opacity: 0;
      }
  }
}

.job-list-header {
  .registration-form-box {

      .form-control,
      .form-select {
          padding: 6px 8px 6px 45px;
      }
  }
}

.wedget-popular-title {
  .popular-box {
      position: relative;
      padding: 6px;
      border-radius: 5px;
      margin-top: 10px;
      border: 1px solid rgba($gray-300, 0.65);

      .number {
          text-align: center;
          width: 26px;
          height: 26px;
          line-height: 26px;
          border-radius: 5px;
          font-size: 14px;
          color: $primary;
          background-color: rgba($primary, 0.15);
      }
  }
}

.bookmark-post, .job-box.bookmark-post{
  .bookmark-label {
      background-color: $primary;
  }

}

.breadcrumb-light-item + .breadcrumb-item::before{
  color: #fff !important;
  padding: 0 20px;
}
