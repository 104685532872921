.xx-avatar {

  .avatar-text {
    display: inline-block;
    text-align: center;
  }

  &.avatar-xs {
    .avatar-text {
      font-size: 1.1rem;
      line-height: 2rem;
      height: 2rem;
      width: 2rem;
    }
  }

  &.avatar-sm {
    .avatar-text {
      font-size: 1.25rem;
      line-height: 3rem;
      height: 3rem;
      width: 3rem;
    }
  }

  &.avatar-md {
    .avatar-text {
      font-size: 1.5rem;
      line-height: 4.5rem;
      height: 4.5rem;
      width: 4.5rem;
    }
  }

  &.avatar-lg {
    .avatar-text {
      font-size: 1.75rem;
      line-height: 6rem;
      height: 6rem;
      width: 6rem;
    }
  }


  &.avatar-xl {
    .avatar-text {
      font-size: 2.25rem;
      line-height: 7.5rem;
      height: 7.5rem;
      width: 7.5rem;
    }
  }

}
