.bs-wizard {
  border-bottom: solid 1px $gray-700;
  padding: 0 0 10px 0;
}

.bs-wizard>.bs-wizard-step {
  padding: 0;
  position: relative;
}

.bs-wizard>.bs-wizard-step .bs-wizard-stepnum {
  color: $gray-700;
  font-size: 16px;
  margin-bottom: 5px;
}


.bs-wizard>.bs-wizard-step>.bs-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: tint-color($primary, 80%);
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot:after {
  content: ' ';
  width: 14px;
  height: 14px;
  background: $primary;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.bs-wizard>.bs-wizard-step>.progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
}

.bs-wizard>.bs-wizard-step>.progress>.progress-bar {
  width: 0px;
  box-shadow: none;
  background: tint-color($primary, 80%);
  transition:none;
}

.bs-wizard>.bs-wizard-step.complete>.progress>.progress-bar {
  width: 100%;
  transition:none;
}

.bs-wizard>.bs-wizard-step.active>.progress>.progress-bar {
  width: 50%;
  transition:none;
}

.bs-wizard>.bs-wizard-step:first-child.active>.progress>.progress-bar {
  width: 0%;
  transition:none;
}

.bs-wizard>.bs-wizard-step:last-child.active>.progress>.progress-bar {
  width: 100%;
  transition:none;
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot {
  background-color: #f5f5f5;
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot:after {
  opacity: 0;
}

.bs-wizard>.bs-wizard-step:first-child>.progress {
  left: 50%;
  width: 50%;
}

.bs-wizard>.bs-wizard-step:last-child>.progress {
  width: 50%;
}

.bs-wizard>.bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}