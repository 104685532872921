.navbar-header .logo-sm img {
  max-width: 36px;
}

@media (max-width: 991.98px) {
  .navbar-header .logo-sm img {
    max-width: 22px;
  }
}

// user side bar

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

// profilebar-ul-list
.profilebar-ul-list {

  li {
    a:not(.btn) {
      color: tint-color($sidebar-dark-menu-item-color, 60%);
      &:hover:not(.active) {
        color: $primary !important;
      }
    }
  }

}

// sidebar-ul-list
.sidebar-ul-list {

  li {
    margin-bottom: 0.45rem;

    a {
      font-size: $font-size-base;
      padding: $btn-padding-y $btn-padding-x;
      color: $sidebar-dark-menu-item-color;

      &.active {
        color: $primary !important;
        border-left: 2px solid $primary;
        background: tint-color($cyan-100, 60%);
        cursor: default;
      }

      &:hover:not(.active) {
        background-color: $gray-300;
        color: $primary !important;
      }
    }
  }
}

// user center
.user-center-main-content {
  &>.card{
    min-height: 74vh;

    .card-body {
      min-height: 700px;
    }
  }

  @media (max-width: 768px) {
    &>.card{
      min-height: auto;
    }
  }
}

@include media-breakpoint-up(md) {
  .user-center-main-content {
    margin-left: calc( 220px + #{$spacer} ) ;
  }

  .user-side-bar {
    float: left;
    width: 220px;
  }
}

@include media-breakpoint-up(lg) {

  .user-center-main-content {
    margin-left: calc( 260px + #{$spacer} ) ;
  }

  .user-side-bar {
    width: 260px;
  }
}

// zm-react-datepicker

.zm-react-datepicker.custom-edit-time {
  .react-datepicker__input-container {
      display: flex;

      > * {
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
      }

      .btn-link {
        color: $btn-link-color !important;
        background: transparent !important;
        border: none !important;
      }

      .text-helper {
        @include button-size($btn-padding-y, 0, $btn-font-size, $btn-border-radius);
      }
  }
}

.cursor-default {
  cursor: default;
}

// react-datepicker
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}

// list group seprater
.list-group-item-line {
  border: $list-group-border-width solid $list-group-border-color;
  border-width: 0 0 1px;
}

.comments {

  .flex-grow-1 p {
    font-size: 15px;
    line-height: 1.8;
    color: rgb(68, 68, 68) !important;
  }

  .flex-grow-1 h5 {
    color: #444 !important;
  }

}

@media (max-width: 575.98px) {

  .page-link {
    display: none;
  }

  .active .page-link {
    display: block;
  }

  .page-link.page-link-icon {
    display: block;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.bg-none {
  background: none !important;
}


.text-divider{margin: 2em 0; line-height: 0; text-align: center;}
.text-divider span{background-color: #fff; padding: 1em;}
.text-divider:before{ content: " "; display: block; border-top: 1px solid #eeeeee; border-bottom: 1px solid #f7f7f7;}

//  back end
.mailer-item {

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  cursor: pointer;

  img {
      width: 150px;
      border: 1px solid #ddd;
      display: inline-block;
  }



  .form-check {
      margin: 0.8rem 0;
  }
}

// custom

.w-60-px {
  width: 60px;
}

.w-120-px {
  width: 120px;
}

.w-180-px {
  width: 180px !important;
}

.ps-sm-120-px {
  padding-left: 0;
}
@include media-breakpoint-up(sm) {
  .ps-sm-120-px {
    padding-left: 120px;
  }
}

.w-30-px {
  width: 30px;
}

.w-50-px {
  width: 50px;
}

.w-90-px {
  width: 90px;
}

.ps-sm-90-px {
  padding-left: 0;
}
@include media-breakpoint-up(sm) {
  .ps-sm-90-px {
    padding-left: 90px;
  }
}

// upload image

.is-invalid .square-upload-button {
  border-color: $form-feedback-invalid-color !important;

}

.square-upload-button {
  border-style: dashed !important;
  border-color: $input-border-color ;
  background-color: tint-color( $light , 60% );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  img.image-md {
    width: 56px;
    height: 56px;
    display: block;
    @extend .rounded-2;
  }

  i {
    color: $input-placeholder-color;
  }

  button.btn-cancel {
    right: -2px;
    top: -8px;
    border-color: $input-border-color ;
    padding: 2px;
    background-color: #fff;
    padding: 3px;

    &:focus {
      border-color: $input-border-color ;
    }

    &:hover {
      // border-color: $primary ;
      box-shadow: $box-shadow;
      background-color: tint-color( $light , 60% );
    }
  }


  button.btn-edit {
    right: -2px;
    bottom: -8px;
    border-color: $input-border-color ;
    padding: 2px;
    background-color: #fff;
    padding: 3px;

    &:focus {
      border-color: $input-border-color ;
    }

    &:hover {
      // border-color: $primary ;
      box-shadow: $box-shadow;
      background-color: tint-color( $light , 60% );
    }
  }

  &:focus {
    border-color: $input-border-color ;
  }

  &:hover {
    border-color: $input-border-color ;
    // background-color: tint-color( $light , 20% );

    i {
      // color: shade-color( $input-placeholder-color , 40% );
    }
  }
}

// date picker
.react-date-picker-range {
  min-width: 220px !important;
}

// blink
.highlighted-post {
  animation: highlighted-post-fade 3s;
  animation-timing-function: ease-out
}

@keyframes highlighted-post-fade {
  0% {
      background-color: $cyan-100;
  }

  100% {
      background-color: rgba(0,0,0,0)
  }
}


// react select


.country-select__value-container .text-label {
  display: none;
}

.react-select__menu {
  z-index: 1000 !important;

  .react-select__option.react-select__option--is-focused {
    // cint-color: $primary;
    background-color: tint-color($primary, 80%) !important;
    color: inherit !important;
  }
}




.react-select {



  .react-select__control {
    width: 100%;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    box-shadow: none !important;

    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &.react-select__control--is-focused.react-select__control--menu-is-open {
      color: $input-focus-color;
      background-color: $input-focus-bg !important;
      border-color: $input-focus-border-color !important;
      outline: 0;

      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      }

      @else {
        box-shadow: $input-focus-box-shadow !important;
      }
    }

    &::-webkit-date-and-time-value {
      height: if(unit($input-line-height)=="", $input-line-height * 1em, $input-line-height);
    }

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
      opacity: 1;
    }
  }

}


.react-select.is-invalid {

  @include form-validation-state-selector('invalid') {

    .react-select__control {
      border-color: $form-feedback-invalid-color;

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: escape-svg($form-feedback-icon-invalid);
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
        box-shadow: none;
      }

      &.react-select__control--is-focused.react-select__control--menu-is-open {
        border-color: $form-feedback-invalid-color !important;
        box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($form-feedback-invalid-color, $input-btn-focus-color-opacity) !important;
      }
    }
  }

}

.select-moblie-country-flag {
  background: url(../../../images/plugins/select-moblie-country-flag.png) no-repeat 0 0;
  background-position: -1px 0;
  background-size: 24px 3876px;
  width: 24px;
  height: 16px;
  display: inline-block;
  overflow: hidden;
}

// form-control .focus

.form-control {

  // Customize the `:focus` state to imitate native WebKit styles.
  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    }

    @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.form-control.is-invalid {

  border-color: $form-feedback-invalid-color;

  @if $enable-validation-icons {
    padding-right: $input-height-inner;
    background-image: escape-svg($form-feedback-icon-invalid);
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;
  }

  &.focus {
    border-color: $form-feedback-invalid-color !important;
    box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($form-feedback-invalid-color, $input-btn-focus-color-opacity)
  }

}

.input-login-with-number {
  padding-left: 114px;
}

// input with icon
// <div class="input-group with-icon">
//     <input class="form-control rounded" name=suche type=search placeholder=Suchtext autocomplete=off>
//     <i class="fa fa-search"></i>
// </div>
//hide nasty ie clear thingie
.input-group.with-icon>input::-ms-clear {
  display: none
}

.input-group.with-icon>input {
  border-radius: $input-border-radius !important;
}

.input-group.with-icon.is-invalid>input {
  background: none;
}


//general placement stuffs
.input-group.with-icon>i {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  position: absolute;
  z-index: 3;
  top: 50%;
}



//icon right (default)
.input-group.with-icon>i,
.input-group.with-icon.icon-right>i {
  right: .7rem;
}

.input-group.with-icon.is-invalid>i,
.input-group.with-icon.icon-right.is-invalid>i {
  color: $form-feedback-invalid-color;
}

.input-group.with-icon>input,
.input-group.with-icon.icon-right>input {
  padding-right: 1.7rem;
}


//icon left
.input-group.with-icon.icon-left>i {
  left: .7rem;
}

.input-group.with-icon.icon-left>input {
  padding-left: 1.7rem;
}

//shy icon, hides on click of input box
.input-group.with-icon.icon-shy>i {
  z-index: 1;
}

// Quill

.ql-editor {
  @include font-size($input-font-size);
  ol, ul {
    padding-left: 0 !important;
  }
}

// canves
.offcanvas-xx {
  width: 600px !important;
  border: none !important;

  .offcanvas-title {
    color: #fff !important;
  }
  .btn-close {
    color: #fff !important;
    background: transparent escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")) center / $btn-close-width auto no-repeat;    }}

@media (max-width: 768px) {
  .offcanvas-xx {
    width: 400px !important;
  }
}

.btn-send-sms {
  padding: 0.28rem 0.8rem !important;
  background-color: #eff2fe;
  margin: 0.24rem 0.26rem;
}

.btn-send-sms-info {
  padding: 0.28rem 0.8rem !important;
  background-color: #f3f4f6;
  margin: 0.24rem 0.26rem;
}

// datatable
.react-bootstrap-table th {
  vertical-align: middle !important;

  .order {
    display: inline-block;

    .caret {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

